export interface ReferenceProduitModel {
	Enseignes: string
	Ref_produit: string
	Montant_max: string
	Montant_min: string
}

export const INITIAL_REFERENCE_PRODUIT: ReferenceProduitModel = {
	Enseignes: '',
	Ref_produit: '',
	Montant_max: '0',
	Montant_min: '0'
}
