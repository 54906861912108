export interface ConfigurationsModel {
	applicationIsDisponible: boolean
	dureeDeLaSessionEnSecondes: number
	http: {
		timeout: number
	}
	links: {
		contact: {
			email: string
			lastname: string
			firstname: string
		}
		email: string
		help: string
		manual: string
		needHelp: string
		socUrl: string
	}
}

export const INITIAL_CONFIGURATIONS: ConfigurationsModel = {
	applicationIsDisponible: false,
	dureeDeLaSessionEnSecondes: 0,
	http: {
		timeout: 0
	},
	links: {
		contact: {
			email: '',
			lastname: '',
			firstname: ''
		},
		email: '',
		help: '',
		manual: '',
		needHelp: '',
		socUrl: ''
	}
}
