import React, { useContext, useEffect } from 'react'
import './App.css'
import { Grid } from '@mui/material'
import Root from './Root'
import { useQuery } from 'react-query'
import { fetchConfigurations } from './api/configurationsApi'
import { AppContext } from './context/AppContext'
import { INITIAL_CONFIGURATIONS } from './types/configurationsModel'
import { fetchSession } from './api/sessionApi'
import SnackBar from '../components/snackBar/SnackBar'
import { INITIAL_SNACKBAR } from '../components/snackBar/types/snackBarModel'
import MenuTop from '../modules/menu/MenuTop'
import { linksEnum } from './enums/linksEnum'
import MenuLeft from '../modules/menu/MenuLeft'
import Ariane from '../components/ariane/Ariane'
import axios, { AxiosError } from 'axios'
import { useLocation } from 'react-router-dom'
import { colorSnackBarEnum } from './enums/colorSnackBarEnum'

function App() {
	const START_REINIT_URL = '/reinitialiser-mot-de-passe'
	const { setConfigurations, setSession, setSnackBar, setSesionFetched } =
		useContext(AppContext)
	const location = useLocation()

	const configurations = useQuery('configurations', fetchConfigurations, {
		refetchOnWindowFocus: false,
		refetchOnMount: false,
		refetchOnReconnect: false,
		suspense: false
	})
	useQuery('sessions', fetchSession, {
		refetchOnWindowFocus: false,
		refetchOnMount: false,
		refetchOnReconnect: false,
		suspense: false,
		onSuccess: (data) => {
			setSession(data)
			setSesionFetched(true)
		},
		onError: (data) => setSesionFetched(true)
	})

	useEffect(() => {
		setConfigurations(configurations.data ?? INITIAL_CONFIGURATIONS)
	}, [configurations])

	// Intercepte les réponses reçues par axios
	axios.interceptors.response.use(
		(response) => response,
		(error: AxiosError) => {
			if (error.response?.data && error.response?.data.messages) {
				const messages = error.response?.data.messages
				const errorMsg: string = Array.isArray(messages) ? messages.join('; ') : messages

				setSnackBar({
					id: error.name,
					color: 'backgrounds.buttonRed',
					open: true,
					message: errorMsg,
					icon: colorSnackBarEnum.red
				})
			}

			// On renvoie l'utilisateur vers le login s'il reçoit une forbidden
			if (error.response?.status === 403 && location.pathname !== linksEnum.login) {
				window.location.href = linksEnum.login
			}
		}
	)

	return (
		<Grid
			container
			className="App"
			sx={{
				height: '100%',
				flexDirection: 'column'
			}}
		>
			{location.pathname !== linksEnum.login &&
				!location.pathname.startsWith(START_REINIT_URL) && <MenuTop />}
			<Grid
				container
				sx={{
					height: '100%',
					flex: 100,
					overflow: 'auto'
				}}
			>
				{location.pathname !== linksEnum.login &&
					!location.pathname.startsWith(START_REINIT_URL) && (
					<Grid container item xs={2}>
						<MenuLeft />
					</Grid>
				)}
				<Grid
					container
					item
					xs={
						location.pathname !== linksEnum.login &&
						!location.pathname.startsWith(START_REINIT_URL)
							? 10
							: 12
					}
					sx={{
						flexDirection: 'column',
						padding:
							location.pathname !== linksEnum.login &&
							!location.pathname.startsWith(START_REINIT_URL)
								? '20px'
								: 0
					}}
				>
					{location.pathname !== linksEnum.login &&
						!location.pathname.startsWith(START_REINIT_URL) && <Ariane />}
					<Grid
						sx={{
							flex: '100 !important'
						}}
					>
						<Root />
						<SnackBar snackBarData={INITIAL_SNACKBAR} />
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	)
}

export default App
