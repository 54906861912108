import { Box, Grid } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { Field, Form } from 'react-final-form'
import FormTextField from '../../components/fields/FormTextField'
import Button from '../../components/button/Button'
import { buttonTypesEnum } from '../../components/button/enums/buttonTypesEnum'
import { submitModel } from '../../types/reinitialisationModel'
import { linksEnum } from '../../containers/enums/linksEnum'
import { useNavigate, useParams } from 'react-router-dom'
import axios from 'axios'
import { AppContext } from '../../containers/context/AppContext'
import { colorSnackBarEnum } from '../../containers/enums/colorSnackBarEnum'
import { activateAccount } from './api/reinitialisationAPI'

const Reinitialisation = () => {
	const navigate = useNavigate()
	const { id, token } = useParams()
	const { setSnackBar } = useContext(AppContext)
	const [show, setShow] = useState<boolean>(false)
	const [disabled, setDisabled] = useState<boolean>(true)
	const [error, setError] = useState<string>('')
	const [errorConfirmation, setErrorConfirmation] = useState<string>('')
	const [motDePasse, setMotDePasse] = useState<string>('')
	const [motDePasseConfirmation, setMotDePasseConfirmation] = useState<string>('')
	const onSubmit = (values: submitModel) => {
		values.token = token ?? ''
		values.userId = parseInt(id ?? '0') ?? 0
		activateAccount(values).then((response: any) => {
			if (response.statut === 'OK') {
				setSnackBar({
					id: 'reinitialisation',
					message: response.messages[0],
					open: true,
					color: 'backgrounds.green'
				})
				navigate(linksEnum.login)
			} else {
				setSnackBar({
					id: 'reinitialisation',
					message: response.messages[0],
					open: true,
					color: 'backgrounds.buttonRed',
					icon: colorSnackBarEnum.red
				})
			}
			console.log(response)
		})
	}

	const onKeyup = (event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		setMotDePasse((event.target as HTMLInputElement).value)
	}

	const onKeyupConfirmation = (
		event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>
	) => {
		setMotDePasseConfirmation((event.target as HTMLInputElement).value)
	}

	useEffect(() => {
		axios
			.get('/api/reinitialiser-mot-de-passe/' + id + '/' + token)
			.then((response) => {
				setShow(true)
			})
			.catch((reason) => {
				setSnackBar({
					id: 'jetonReinitialisation',
					message: reason.response.data.messages[0],
					open: true,
					color: 'backgrounds.orange',
					icon: colorSnackBarEnum.orange
				})
				navigate(linksEnum.login)
			})
	}, [])

	useEffect(() => {
		const regex = new RegExp('(?=^.{8,}$)(?=.*\\d)(?=.*[!@#$%^&*()\\-_+.~"{}|/§:;?,]+)(?![.\\n])(?=.*[A-Z])(?=.*[a-z]).*$')
		if (motDePasse !== '' && !regex.test(motDePasse)) {
			setError('Veuillez entrer au moins 8 caractères minimums, 1 minuscule, 1 majuscule, 1 nombre et 1 caractère spécial')
		} else {
			setError('')
		}
		if (motDePasse !== motDePasseConfirmation) {
			setErrorConfirmation('Veuillez entrer le même mot de passe')
		} else {
			setErrorConfirmation('')
		}
	}, [motDePasse, motDePasseConfirmation])

	useEffect(() => {
		setDisabled(motDePasse === '' || error !== '' || errorConfirmation !== '')
	}, [error, errorConfirmation])

	useEffect(() => {}, [disabled])

	return (
		<Grid
			sx={{
				backgroundColor: 'backgrounds.darkBlue',
				height: '100vh',
				p: '60px 10px'
			}}
			container
			item
			xs={12}
			justifyContent="center"
		>
			{/* Container de la partie centrale du formulaire */}
			<Box
				sx={{
					width: {
						xs: '100%',
						sm: 420
					},
					textAlign: 'center'
				}}
			>
				<img
					src="/img/logo/spirit1.png"
					alt="Logo Spirit of Cadeau"
					width="200"
					height="104"
				/>

				<Grid
					sx={{
						bgcolor: 'backgrounds.blue',
						mt: 5
					}}
					container
					item
					xs={12}
				>
					<Grid container item xs={12}>
						{show && (
							<Form
								onSubmit={onSubmit}
								render={({ handleSubmit }) => (
									<form onSubmit={handleSubmit}>
										<Grid container item xs={12}>
											<Grid
												sx={{
													bgcolor: 'backgrounds.white',
													width: '100%',
													padding: '15px'
												}}
												container
												item
												xs={12}
											>
												<Grid
													container
													item
													xs={12}
													sx={{
														color: 'texts.green',
														textTransform: 'uppercase',
														justifyContent: 'center',
														fontWeight: 300,
														marginTop: '10px',
														marginBottom: '10px'
													}}
												>
													Définissez votre mot de passe
												</Grid>
												<Grid container item xs={12}>
													<Field
														name="nouveauMotDePasse"
														type="password"
														label="Saisissez un mot de passe (8 caractères minimums, 1 minuscule, 1 majuscule, 1 nombre et 1 caractère spécial) :"
														component={FormTextField}
														inputOnKeyUp={onKeyup}
														sx={{
															marginBottom: '10px'
														}}
														meta={{
															touched: true,
															error: `${error}`
														}}
														fontSize="12px"
													/>
												</Grid>

												<Grid
													container
													item
													xs={12}
													sx={{
														marginBottom: '15px'
													}}
												>
													<Field
														name="nouveauMotDePasseConfirmation"
														type="password"
														label="Saisissez à nouveau votre mot de passe :"
														inputOnKeyUp={onKeyupConfirmation}
														component={FormTextField}
														meta={{
															touched: true,
															error: `${errorConfirmation}`
														}}
														fontSize="12px"
													/>
												</Grid>
											</Grid>

											<Grid container item xs={12}>
												<Grid sx={{ p: '15px' }} container item xs={12} justifyContent="center">
													<Grid container item xs={6} justifyContent="left">
														<Button
															bgcolor="buttonRed"
															color="white"
															type={buttonTypesEnum.reset}
															onClick={() => {
																navigate(linksEnum.login)
															}}
														>
															ANNULER
														</Button>
													</Grid>
													<Grid container item xs={6} justifyContent="right">
														<Button
															bgcolor="green"
															color="white"
															type={buttonTypesEnum.submit}
															disabled={disabled}
															opacity={disabled ? 0.5 : 1}
														>
															VALIDER
														</Button>
													</Grid>
												</Grid>
											</Grid>
										</Grid>
									</form>
								)}
							/>
						)}
					</Grid>
				</Grid>
			</Box>
		</Grid>
	)
}
export default Reinitialisation
