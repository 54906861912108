import { Box, Grid } from '@mui/material'
import React, { useContext, useMemo, useState } from 'react'
import { Field, Form } from 'react-final-form'
import arrayMutators from 'final-form-arrays'
import Button from '../../components/button/Button'
import { buttonTypesEnum } from '../../components/button/enums/buttonTypesEnum'
import FormBoxTextField from '../../components/fields/FormBoxTextField'
import { emptyLot, FormLotsModel, initFormLots } from './types/lotModel'
import { formLotsEnum, lotEnum } from './enums/lotEnum'
import { FieldArray } from 'react-final-form-arrays'
import { AppContext } from '../../containers/context/AppContext'
import Link from '../../components/link/Link'
import { getRefsProduitsApi } from './api/getRefsProduitsApi'
import { getInfoRefProduitApi } from './api/getInfoRefProduitApi'
import PopInHelpActivation from './components/PopInHelpActivation'
import PopInErrorVerify from './components/PopInErrorVerify'
import PopInVerify from './components/PopInVerify'
import { FormErrors } from '../../components/fields/types/fieldsModel'
import FormSelectField from '../../components/fields/FormSelectField'
import { useQuery } from 'react-query'
import { fetchPointsdeVente } from '../pointsDeVente/api/pointsDeVenteApi'
import { FormSelectFieldModel } from '../../components/fields/types/formSelectFieldModel'
import { sessionEnum } from '../../containers/enums/sessionEnum'
import { useLocation, useNavigate } from 'react-router-dom'
import { PointDeVenteModel } from '../pointsDeVente/types/pointDeVenteModel'
import { linksEnum } from '../../containers/enums/linksEnum'

interface SaisieLocationState {
	pointDeVente: PointDeVenteModel
}

const Saisie = () => {
	const { configurations, session } = useContext(AppContext)
	const location = useLocation()
	const navigate = useNavigate()
	const [openHelpActivationPopIn, setHelpActivationOpenPopIn] = useState<boolean>(false)

	// Récupération des PDV
	const PDVResult = useQuery(
		['PointsdeVente', session.role],
		() => fetchPointsdeVente(false),
		{
			refetchOnWindowFocus: false,
			refetchOnMount: false,
			refetchOnReconnect: false,
			suspense: false,
			enabled: session.role === sessionEnum.agentDePointDeVente
		}
	)

	const pdvState = location.state
		? (location.state as SaisieLocationState).pointDeVente
		: null

	const cantAccess =
		session.role !== sessionEnum.agentDePointDeVente &&
		session.role !== sessionEnum.disconnected &&
		!pdvState

	if (cantAccess) {
		navigate(linksEnum.login)
	}

	const validate = (values: FormLotsModel) => {
		const errors: FormErrors<FormLotsModel> = {
			lots: []
		}

		// Dans la mesure où le champ est masqué s'il n'y a qu'un élément, on ne fait pas de vérification
		if (PDVResult.data && PDVResult.data.length !== 1) {
			if (!values.pdv || values.pdv === 0) {
				errors.pdv = 'Requis*'
			}
		}

		values.lots.forEach((value, index) => {
			// Check que au moins une valeur est renseignée sinon on prend pas en compte.
			if (
				value.Numero_logistique_premiere_carte ||
				value.Numero_logistique_derniere_carte ||
				value.Montant_par_carte
			) {
				// Check existence autres valeurs.
				if (!value.Numero_logistique_premiere_carte) {
					if (typeof errors.lots![index] === 'undefined') {
						errors.lots![index] = {
							[lotEnum.Numero_logistique_premiere_carte]:
								'Le numéro logistique de la premiere carte est obligatoire.'
						}
					} else {
						errors.lots![index]!.Numero_logistique_premiere_carte =
							'Le numéro logistique de la premiere carte est obligatoire.'
					}
				}
				if (!value.Numero_logistique_derniere_carte) {
					if (typeof errors.lots![index] === 'undefined') {
						errors.lots![index] = {
							[lotEnum.Numero_logistique_derniere_carte]:
								'Le numéro logistique de la derniere carte est obligatoire.'
						}
					} else {
						errors.lots![index]!.Numero_logistique_derniere_carte =
							'Le numéro logistique de la derniere carte est obligatoire.'
					}
				}
				if (!value.Montant_par_carte) {
					if (typeof errors.lots![index] === 'undefined') {
						errors.lots![index] = {
							[lotEnum.Montant_par_carte]: 'Le montant par carte est obligatoire.'
						}
					} else {
						errors.lots![index]!.Montant_par_carte = 'Le montant par carte est obligatoire.'
					}
				}

				// Check comparaison numéros cartes début / fin.
				if (
					value.Numero_logistique_premiere_carte &&
					value.Numero_logistique_derniere_carte
				) {
					if (
						parseInt(value.Numero_logistique_premiere_carte) >
						parseInt(value.Numero_logistique_derniere_carte)
					) {
						if (typeof errors.lots![index] === 'undefined') {
							errors.lots![index] = {
								[lotEnum.Numero_logistique_premiere_carte]:
									'Veuillez entrer un numéro inférieur ou égal à celui de la dernière carte et sans les zéros non-significatifs.'
							}
						} else {
							errors.lots![index]!.Numero_logistique_premiere_carte =
								'Veuillez entrer un numéro inférieur ou égal à celui de la dernière carte et sans les zéros non-significatifs.'
						}
						if (typeof errors.lots![index] === 'undefined') {
							errors.lots![index] = {
								[lotEnum.Numero_logistique_derniere_carte]:
									'Veuillez entrer un numéro supérieur ou égal à celui de la première carte et sans les zéros non-significatifs.'
							}
						} else {
							errors.lots![index]!.Numero_logistique_derniere_carte =
								'Veuillez entrer un numéro supérieur ou égal à celui de la première carte et sans les zéros non-significatifs.'
						}
					}
				}
			}
		})

		return errors
	}

	const onSubmit = async (values: FormLotsModel) => {
		const errors: any = {}

		// Dans la mesure où le champ est masqué s'il n'y a qu'un élément, il faut set la valeur manuellement
		if (PDVResult.data && PDVResult.data.length === 1) {
			values.pdv = PDVResult.data[0].Id_points_de_ventes
		}

		// Si on a un autre rôle qu'agent de point de vente, il faut set la valeur avec celle du location.state
		if (session.role !== sessionEnum.agentDePointDeVente) {
			values.pdv = pdvState!.Id_points_de_ventes
		}

		const idEnseigne =
			session.role === sessionEnum.agentDePointDeVente
				? PDVResult.data!.find((pdv) => pdv.Id_points_de_ventes === values.pdv)!.Id_enseignes
				: pdvState!.Id_enseignes

		// Appels /api/ref_produit/cartes et /api/ref_produit
		for (const lot of values.lots) {
			const fetchRefsCartes = await getRefsProduitsApi(lot)

			for (const cartesRefProduit of fetchRefsCartes) {
				lot.Reference = cartesRefProduit.Reference_produit
				const fetchRefProduitInfo = await getInfoRefProduitApi(
					cartesRefProduit.Reference_produit,
					idEnseigne
				)

				// Check comparaison montant avec les limites associées à la reférence produit.
				if (
					fetchRefProduitInfo &&
					((lot.Montant_par_carte && lot.Montant_par_carte < fetchRefProduitInfo.montantMin) ||
						(lot.Montant_par_carte && lot.Montant_par_carte > fetchRefProduitInfo.montantMax))
				) {
					if (typeof errors[fetchRefProduitInfo.refProduit] === 'undefined') {
						errors[fetchRefProduitInfo.refProduit] = cartesRefProduit.Cartes
					} else {
						errors[fetchRefProduitInfo.refProduit] += cartesRefProduit.Cartes
					}
				}
			}
		}

		return errors
	}

	const displayPopIn = () => {
		setHelpActivationOpenPopIn(true)
	}

	const closeHelpActivationPopIn = () => {
		setHelpActivationOpenPopIn(false)
	}

	const pdvOptions: FormSelectFieldModel[] = useMemo(
		() =>
			(PDVResult.data || []).map((pdvData) => ({
				value: pdvData.Id_points_de_ventes,
				label: pdvData.Nom_du_PDV
			})),
		[PDVResult.data]
	)

	if (cantAccess) {
		return <></>
	}

	return (
		<Grid
			container
			item
			xs={12}
			sx={{
				backgroundColor: 'backgrounds.white',
				marginTop: '30px'
			}}
		>
			<Box
				component="div"
				sx={{
					borderBottom: 'solid 1px rgba(0, 0, 0, .1)',
					width: '100%',
					padding: '8px 15px',
					display: 'flex',
					justifyContent: 'space-between'
				}}
			>
				<Box
					component="h1"
					sx={{
						margin: 0,
						display: 'inline-block',
						fontWeight: 400,
						fontSize: '20px',
						lineHeight: '26px'
					}}
				>
					Activer des cartes en lots{' '}
					{session.role !== sessionEnum.agentDePointDeVente &&
						session.role !== sessionEnum.disconnected &&
						`- ${pdvState!.Nom_du_PDV}`}
				</Box>
				<Box>
					<Link
						href={configurations.links.needHelp}
						target="_blank"
						bold={false}
						hover={false}
					>
						<Button bgcolor="buttonBlue" color="white">
							Besoin d'aide ?
						</Button>
					</Link>
				</Box>
			</Box>
			<Grid
				sx={{
					padding: '8px 15px',
					width: '100%'
				}}
			>
				<p>
					Vous avez la possibilité d'activer jusqu'à 5 lots de cartes avec des montants
					différents.
				</p>
				<Form
					mutators={{
						...arrayMutators
					}}
					onSubmit={onSubmit}
					validate={validate}
					validateOnBlur={true}
					initialValues={initFormLots}
					render={({
						handleSubmit,
						values,
						hasValidationErrors,
						submitSucceeded,
						hasSubmitErrors,
						submitErrors,
						form,
						submitting
					}) => (
						<form onSubmit={handleSubmit}>
							<Grid
								container
								item
								xs={12}
								sx={{
									marginBottom: '15px'
								}}
							>
								{PDVResult.data && PDVResult.data.length !== 1 && (
									<Grid container item xs={12} spacing={3} sx={{ mb: 2 }}>
										<Grid container item xs={12} md={4}>
											<Field
												name={formLotsEnum.pdv}
												component={FormSelectField}
												label="Point de vente concerné"
												options={pdvOptions}
											/>
										</Grid>
									</Grid>
								)}

								<FieldArray
									name={formLotsEnum.lots}
									render={({ fields, meta }) => {
										return (
											<>
												{fields.map((name: string, index: number) => (
													<React.Fragment key={name}>
														{fields.length && fields.length > 1 && (
															<Grid item xs={12}>
																<Box
																	component="strong"
																	sx={{
																		textDecoration: 'underline'
																	}}
																>
																	{`Lot ${index + 1}`}
																</Box>
															</Grid>
														)}
														<Grid
															container
															item
															xs={12}
															spacing={3}
															sx={{
																marginBottom: '20px'
															}}
														>
															<Grid item xs={4}>
																<Field
																	name={`${name}.${lotEnum.Numero_logistique_premiere_carte}`}
																	type="text"
																	label="Numéro logistique de la première carte"
																	component={FormBoxTextField}
																	hasPopIn={true}
																	popInOnClick={displayPopIn}
																	sx={{
																		marginBottom: '10px'
																	}}
																	maxLength={10}
																/>
															</Grid>
															<Grid item xs={4}>
																<Field
																	name={`${name}.${lotEnum.Numero_logistique_derniere_carte}`}
																	type="text"
																	label="Numéro logistique de la dernière carte"
																	component={FormBoxTextField}
																	sx={{
																		marginBottom: '10px'
																	}}
																	maxLength={10}
																/>
															</Grid>
															<Grid item xs={4}>
																<Field
																	name={`${name}.${lotEnum.Montant_par_carte}`}
																	type="number"
																	label="Montant par carte (€)"
																	component={FormBoxTextField}
																	sx={{
																		marginBottom: '10px'
																	}}
																/>
															</Grid>
														</Grid>
													</React.Fragment>
												))}
												{(fields.length ?? 0) < 5 && (
													<Box
														sx={{
															margin: '10px 0',
															width: '100%'
														}}
													>
														<Button
															bgcolor="buttonBlue"
															color="white"
															type={buttonTypesEnum.button}
															onClick={() => fields.push(emptyLot)}
														>
															+ Ajouter un nouveau lot
														</Button>
													</Box>
												)}
											</>
										)
									}}
								/>
							</Grid>

							<Box
								sx={{
									margin: '10px 0',
									width: '100%',
									display: 'flex',
									flexDirection: 'column'
								}}
							>
								<Box
									component="strong"
									sx={{
										display: 'block',
										marginBottom: '5px'
									}}
								>
									Commentaires : (250 caractères maximum)
								</Box>
								<Field
									name={formLotsEnum.commentaire}
									type="textarea"
									component={FormBoxTextField}
									multiline
									rows={5}
									maxLength={250}
									placeholder="Ecrivez un commentaire ou la référence de la commande"
									sx={{
										marginBottom: '10px'
									}}
								/>
							</Box>

							<Grid
								sx={{
									p: '15px',
									bgcolor: 'backgrounds.lightlightGrey'
								}}
								container
								item
								xs={12}
								justifyContent="flex-end"
							>
								<Box
									sx={{
										margin: '0 10px'
									}}
								>
									<Button
										bgcolor="buttonRed"
										color="white"
										type={buttonTypesEnum.reset}
										onClick={() => form.reset(initFormLots)}
									>
										Annuler
									</Button>
								</Box>
								<Box
									sx={{
										margin: '0 2px'
									}}
								>
									<Button
										bgcolor="buttonBlue"
										color="white"
										type={buttonTypesEnum.submit}
										disabled={
											Object.values(values.lots).every((value, index) => {
												return value === emptyLot
											}) ||
											hasValidationErrors ||
											submitting
										}
									>
										{submitting ? 'Vérification en cours' : 'Vérifier'}
									</Button>
								</Box>
							</Grid>

							<PopInHelpActivation
								onCloseDialog={closeHelpActivationPopIn}
								open={openHelpActivationPopIn}
							/>
							{submitErrors && (
								<PopInErrorVerify
									onCloseDialog={() => form.reset(values)}
									open={hasSubmitErrors}
									errors={submitErrors}
								/>
							)}
							<PopInVerify
								onCloseDialog={() => form.reset(values)}
								open={submitSucceeded && values.lots.length > 0}
								values={values}
							/>
						</form>
					)}
				/>
			</Grid>
		</Grid>
	)
}
export default Saisie
