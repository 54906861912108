import Dialog from '../../../components/dialog/Dialog'
import React, { useContext, useState } from 'react'
import FormTextField from '../../../components/fields/FormTextField'
import { Field, Form } from 'react-final-form'
import { Grid } from '@mui/material'
import Button from '../../../components/button/Button'
import { buttonTypesEnum } from '../../../components/button/enums/buttonTypesEnum'
import { AppContext } from '../../../containers/context/AppContext'
import { ReferenceProduitModel } from '../types/referenceProduitModel'
import { colorSnackBarEnum } from '../../../containers/enums/colorSnackBarEnum'
import { addRefCard } from '../api/referencesProduitApi'

interface AcceptingProps {
	onCloseDialog: () => void
	open: boolean
	montant: ReferenceProduitModel
	enseigneId: string
}

type PopinModificationMontantProps = AcceptingProps

const PopinModificationMontant: React.FC<PopinModificationMontantProps> = ({
	onCloseDialog,
	open,
	montant,
	enseigneId
}) => {
	const [disabled, setDisabled] = useState<boolean>(true)

	const { setSnackBar } = useContext(AppContext)

	const validate = (values: ReferenceProduitModel) => {
		const errors: any = {}
		let min = 0
		let max = 0
		const valueMin = isNaN(parseInt(values.Montant_min))?0:parseInt(values.Montant_min)
		const valueMax = isNaN(parseInt(values.Montant_max))?0:parseInt(values.Montant_max)
		if (
			!valueMin ||
			valueMin < 1
		) {
			errors.Montant_min = 'Veuillez entrer un nombre entier'
		} else {
			min = valueMin
		}
		if (
			!valueMax ||
			valueMax > 250
		) {
			errors.Montant_max = 'Veuillez entrer un nombre entier'
		} else {
			max = valueMax
		}
		if (min > max) {
			errors.Montant_max =
				'Veuillez entrer un chiffre entier supérieur au montant minimal de chargement'
		}
		setDisabled(Object.keys(errors).length > 0)
		return errors
	}

	const onSubmit = (values: ReferenceProduitModel) => {
		values.Enseignes = enseigneId
		const formData = new FormData()
		formData.append('ref_prod', JSON.stringify(values))
		addRefCard(values.Ref_produit, formData).then((response: any) => {
			if (response.code === 400) {
				setSnackBar({
					id: 'saisieRefProduit',
					message: response.messages[0],
					open: true,
					color: 'backgrounds.buttonRed',
					icon: colorSnackBarEnum.red
				})
			} else {
				setSnackBar({
					id: 'saisieRefProduit',
					message: response.messages[0],
					open: true,
					color: 'backgrounds.green'
				})
				onCloseDialog()
			}
		})
	}

	return (
		<Dialog
			onCloseDialog={onCloseDialog}
			open={open}
			title={`Référence produit : ${montant.Ref_produit}`}
			backgroundColor="#f0ad4e"
		>
			<Form
				onSubmit={onSubmit}
				validate={validate}
				initialValues={montant}
				render={({ handleSubmit, errors }) => (
					<form onSubmit={handleSubmit}>
						<Grid sx={{ p: '20px 24px' }} container item xs={12}>
							<Field
								name="Montant_min"
								type="number"
								label="Montant minimal de chargement"
								component={FormTextField}
								sx={{
									marginBottom: '10px'
								}}
							/>
							<Field
								name="Montant_max"
								type="number"
								label="Montant maximal de chargement"
								component={FormTextField}
								sx={{
									marginBottom: '10px'
								}}
							/>
						</Grid>
						<Grid
							sx={{
								p: '15px',
								bgcolor: 'backgrounds.blue'
							}}
							container
							item
							xs={12}
							justifyContent="center"
						>
							<Grid container item xs={6} justifyContent="left">
								<Button
									bgcolor="buttonRed"
									color="white"
									type={buttonTypesEnum.reset}
									onClick={onCloseDialog}
								>
									RETOUR
								</Button>
							</Grid>
							<Grid container item xs={6} justifyContent="right">
								<Button
									bgcolor="buttonYellow"
									color="white"
									type={buttonTypesEnum.submit}
									disabled={disabled}
									opacity={disabled ? 0.5 : 1}
								>
									MODIFIER
								</Button>
							</Grid>
						</Grid>
					</form>
				)}
			/>
		</Dialog>
	)
}

export default PopinModificationMontant
